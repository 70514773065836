import React, { useEffect } from "react"
import axios from 'axios'
import './payment.scss'
import { usePostError } from '../../utilities/usePostError'
import Header from "../header/header"
import Footer from "../footer/footer"

export const paymentPaths = {
  'renew-tag': 'tag-renewal-payment',
  'decal-replacement': 'decal-replacement-payment',
  'disability-placard': 'disability-placard-payment',
  'duplicate-title-request': 'duplicate-title-request-payment',
  'registration-request': 'registration-request-payment',
  'repo-instructions': 'repo-instructions-payment',
  'personalized-tag': 'personalized-tag-payment',
  'renew-boat': 'boat-renewal-payment'
}

export default function Payment() {
  const getFormProps = () => {
    const path = window.sessionStorage.getItem('mcn-form-path')

    return {
      backPath: path,
      paymentPath: paymentPaths[path]
    }
  }

  const handleError = usePostError()

  function handleBack() {
    const { backPath } = getFormProps()
    window.location.href = '/' + backPath
  }

  const onLoad = async () => {
    const DomUtils = {
      getEl: (selector) => window.document.querySelector(selector),
    
      hasClass: (el, cssClass) => {
        if (el.classList) {
          return el.classList.contains(cssClass)
        }
        return !!el.className.match(new RegExp(`(\\s|^)${cssClass}(\\s|$)`))
      },
    
      removeClass: (el, cssClass) => {
        if (el.classList) {
          el.classList.remove(cssClass)
        } else if (DomUtils.hasClass(el, cssClass)) {
          const reg = new RegExp(`(\\s|^)${cssClass}(\\s|$)`)
          el.className = el.className.replace(reg, ' ')
        }
      },
    }
    
    const SubmitButton = {
      buttonElement: DomUtils.getEl('[data-submit-btn]'),
      loaderElement: DomUtils.getEl('.btn__loader'),
    
      enable: () => {
        SubmitButton.buttonElement.disabled = false
        DomUtils.removeClass(SubmitButton.buttonElement, 'disabled-bkg')
      },
    
      setSubmitState: () => {
        SubmitButton.buttonElement.disabled = true
        SubmitButton.loaderElement.style.display = 'inline-block'
      },
    
      removeSubmitState: () => {
        SubmitButton.buttonElement.disabled = false
        SubmitButton.loaderElement.style.display = 'none'
      }
    }
    
    const config = {
      fields: {
        card: {
          selector: '[data-cc-card]',
        },
        cvv: {
          selector: '[data-cc-cvv]',
        },
        exp: {
          selector: '[data-cc-exp]',
        },
        name: {
          selector: '[data-cc-name]',
          placeholder: 'Full Name',
        },
        postalCode: {
            selector: '[data-cc-postalcode]',
          }
      },
    
      styles: {
        input: {
          'font-size': '15px',
          color: '#00a9e0',
          'font-family': 'Arial',
          background: 'black',
        },
        '.card': {
          'font-family': 'Arial',
        },
        ':focus': {
          color: '#00a9e0',
        },
        '.valid': {
          color: '#43B02A',
        },
        '.invalid': {
          color: '#C01324',
        },
        'input:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 50px white inset',
        },
        'input:focus:-webkit-autofill': {
          '-webkit-text-fill-color': '#00a9e0',
        },
        'input.valid:-webkit-autofill': {
          '-webkit-text-fill-color': '#43B02A',
        },
        'input.invalid:-webkit-autofill': {
          '-webkit-text-fill-color': '#C01324',
        },
        'input::placeholder': {
          color: '#aaa',
        },
      },
    
      classes: {
        empty: 'empty',
        focus: 'focus',
        invalid: 'invalid',
        valid: 'valid',
      },
    }
    
    function authorizeSession(callback) {
      axios.post(`https://mcntags.com/api/authorize-session`).then(response => {
        if (response.status >= 200 && response.status < 300) {
          callback(response.data)
        } else {
          throw new Error("error response: " + response.data)
        }
      })
    }

    const hooks = {
      preFlowHook: authorizeSession,
    }

    const transactionId = window.sessionStorage.getItem('transactionId')

    const onCreate = (paymentForm) => {
      const onSuccess = async (clientToken) => {
        try {
          const { paymentPath } = getFormProps()
          axios.post(`https://mcntags.com/api/${paymentPath}`, { clientToken, transactionId }).then(response => {

            if (response.data.transaction_status === 'approved') {
              SubmitButton.removeSubmitState()
              paymentForm.reset(() => {})
              window.location.href = '/thankyou'

            } else if (response.data.error && response.data.error.messages) {
                alert(response.data.error.messages[0].description)
                
            } else alert("There was a problem processing your transaction")
          })
        } catch(error) {
          handleError(error)
        }
      }
    
      const onError = (error) => {
        console.log("Tokenize Error: " + error.message)
        alert("Tokenization request error: \"" + error.message + "\"")
        SubmitButton.removeSubmitState()
        paymentForm.reset(() => {})
      }
    
      const form = DomUtils.getEl('#form')
    
      form.addEventListener('submit', (e) => {
        e.preventDefault()
        SubmitButton.setSubmitState()
        paymentForm.onSubmit(onSuccess, onError)
      })
    
      const ccFields = window.document.getElementsByClassName('payment-fields')
    
      for (let i = 0; i < ccFields.length; i++) {
        DomUtils.removeClass(ccFields[i], 'disabled')
      }
    
      SubmitButton.enable()
    }
    
    window.firstdata.createPaymentForm(config, hooks, onCreate)
  }

  useEffect(() => {
    const script = document.createElement(`script`)
    script.src = 'https://lib.paymentjs.firstdata.com/prod/client-2.0.0.js'
    document.body.append(script)
    script.addEventListener('load', onLoad)

    return (() => {
      script.remove()
    })
  }, [])

  return (
    <section className="payment-section">
      <Header title='Payment' />
      <div className="container" >
        <div className="title">
          <h1>Payment</h1>
        </div>
        <form id="form">
          <div>
            <label htmlFor="cc-card" className="control-label">Card Number</label>

            <div className="form-controls payment-fields disabled empty" id="cc-card" data-cc-card></div>
          </div>

          <div>
            <label htmlFor="cc-name" className="control-label">Card Holder Name</label>

            <div className="form-controls payment-fields disabled" id="cc-name" data-cc-name></div>
          </div>

          <div className="col1">
            <label htmlFor="cc-exp" className="control-label">Exp Date</label>

            <div className="form-controls payment-fields disabled empty" id="cc-exp" data-cc-exp></div>
          </div>

          <div className="col2">
            <label htmlFor="cc-cvv" className="control-label">CVV</label>

            <div className="form-controls payment-fields disabled empty" id="cc-cvv" data-cc-cvv></div>
          </div>

          <div>
            <label htmlFor="cc-postalCode" className="control-label">ZIP</label>
            <div className="form-controls payment-fields disabled empty" id="cc-postalCode" data-cc-postalcode></div>
          </div>

          <button id="submit" className="btn--primary disabled-bkg" data-submit-btn disabled>
          <span className="btn__loader" style={{ display: 'none' }}>loading...</span>Pay<span data-card-type></span>
          </button>
          <div className="secure">
            <img className="secure-icon" src="/secure.png" alt="" />
            <p className="secure-checkout">Secure First Data® checkout</p>
          </div>
        </form>
        <div className="button-container">
          <button className="back" onClick={handleBack}>
            <span><img src="./arrow-left.svg" alt=""/></span>
            BACK
          </button>
        </div>
      </div>
      <Footer />
    </section>
  )
}